/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, MouseEventHandler} from 'react';
import { ThemeModeComponent } from '../../../assets/ts/layout';
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';


type TableProps = {
  showClosed: boolean;
  handleMouseEnter: MouseEventHandler;
  handleMouseLeave: MouseEventHandler;
  date: string;
  principal: number;
  interest: number;
  fee: number;
  details?: string;
  status: string;
};

const ScheduleTableRow: React.FC<TableProps> = ({ 
  showClosed, 
  handleMouseEnter, 
  handleMouseLeave, 
  date, 
  principal, 
  interest, 
  fee, 
  details,
  status 
}) => {
  const tableRowStyle = {}; // Define your tableRowStyle here
  const total = (principal + interest + fee).toFixed(2);
  const getStatusClassName = (status: string) => {
    if (status === "Paid on Time") {
      return "badge-light-success"; // Use green color for successful
    } else {
      return "badge-light text-dark"; // Use red color for failed
    } 
  };
  return (
    <tr hidden={showClosed} 
        className='table table-row-bordered border-gray-200 align-middle' 
        style={tableRowStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>

      <td><span className='text-dark'>{date}</span></td>
      <td><span className='text-dark d-block mb-1'>${principal}</span></td>
      <td><span className='text-dark d-block mb-1'>${interest}</span></td>
      <td><span className='text-dark d-block mb-1'>${fee.toFixed(2)}</span></td>
      <td className='text-dark'>${total}</td>
      <td className='text-dark'>{details}</td>
      <td className={getStatusClassName(status)} id='scheduled'>
        <span className= {`badge fs-7 ${getStatusClassName(status)}`}>
          {status}
        </span>
      </td>
    </tr>
  );
};

type Props = {
  className: string
}

const TablesWidget15: React.FC<Props> = ({className}) => {
  const {mode} = useThemeMode();
  const tableRowStyle = {
    backgroundColor: ThemeModeComponent.getMode() === 'dark' ? '#333' : '#fff', // Adjust colors as needed
    cursor: 'pointer',
  };
  
  const handleMouseEnter = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const rowIndex = Array.from(e.currentTarget.parentElement!.children).indexOf(e.currentTarget);
    const isOddRow = (rowIndex - 1) % 2 === 0; // Subtracting 1 to skip the title row
    
    let hoverBackgroundColor;
    if (mode === 'dark') {
      hoverBackgroundColor = isOddRow ? '#1a4644' : '#54ede4';
    } else {
      hoverBackgroundColor = isOddRow ? '#cae7e7' : '#51b9b8';
    }
    
    e.currentTarget.style.backgroundColor = hoverBackgroundColor;
    };

  const handleMouseLeave = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.currentTarget.style.backgroundColor = tableRowStyle.backgroundColor;
  };
  
  const [showScheduled, setShowScheduled] = useState<boolean>(false);
  const [showClosed, setShowClosed] = useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<string>('all'); // Define the showStatus state with an initial value of 'all'
  
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    
    setShowStatus(event.target.value);
    // Check if the selected value is 'paid', then show scheduled rows
    if (event.target.value === 'paid') {
      setShowScheduled(true);
    } else {
      setShowScheduled(false);
    }

    if (event.target.value === 'unpaid'){
      setShowClosed(true);
    } else{
      setShowClosed(false);
    }
  };
  return (
    <div className={`card ${className}`}   >
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 d-flex fs-4 margin-left-10px'> 
        <div className='card-title d-flex justify-content-start'>
          <span className='card-label fw-bold fs-3 mb-1'>
              Schedule Information
          </span>
        </div>
        <div className='card-toolbar d-flex justify-content-end' >
          {/* begin::Menu */}
          <div className="toggle-class ">
          <label>
        <select className="form-select" value={showStatus} onChange={handleStatusChange}>
          <option value="all">All</option>
          <option value="paid">Paid</option>
          <option value="unpaid">Unpaid</option>
        </select>
        </label>
          </div>
        </div>
      </div>
      {/* end::Header */} 
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-3 gy-4 table-striped'>
            <thead className=''>
              <tr className='border-bottom border-gray-200 fs-6 text-dark-600 fw-bold bg-primary bg-light bg-opacity-75' >
                <th className='min-w-200px' >Date</th>
                <th className='min-w-200px' >Principal</th>
                <th className='min-w-200px' >Interest</th>
                <th className='min-w-200px' >Fee</th>
                <th className='min-w-200px' >Total</th>
                <th className='min-w-150px' >Details</th>
                <th className='min-w-150px' >Status</th>
              </tr>
            </thead>
            <tbody>  
              <ScheduleTableRow 
                showClosed={showClosed} 
                handleMouseEnter={handleMouseEnter} 
                handleMouseLeave={handleMouseLeave} 
                date="05/02/2023" 
                principal={20.83} 
                interest={4.17}
                fee={30.00} 
                details="FLORIDA DOCUMENTARY STAMP TAX: $5.00 APPLICATION FEE: $25.00"
                status="Paid on Time" 
              />              
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="05/17/2023"
                principal={20.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="06/02/2023"
                principal={20.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="06/20/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="07/03/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="07/17/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="08/02/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="08/17/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="09/05/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="09/18/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="10/02/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
              <ScheduleTableRow
                showClosed={showScheduled}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                date="10/17/2023"
                principal={25.83}
                interest={4.17}
                fee={0.00}
                details=""
                status="Scheduled"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget15}