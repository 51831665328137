/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

type TableCellProps = {
  cellTitle: string
  cellContent: string
}

const TableCell: React.FC<TableCellProps> = ({cellTitle, cellContent}) => {
  return (
    <div className='col'>
      <div className=' min-w-125px py-3 px-4 me-6 mb-3'>
        <span className='text-muted fw-semibold d-block'>
          {cellTitle}
        </span>
        <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
          {cellContent}
        </span>
      </div>
    </div>
  )
}

const TablesWidget91: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title '>
          <span className='card-label fw-bold fs-3 mb-1'>
            Patient Information
            <KTIcon iconName='user-tick' className='fs-2 text-primary ms-2' />
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        <div className='d-flex align-items-center flex-wrap'>
          <TableCell cellTitle='First Name' cellContent='Nancy' />
          <TableCell cellTitle='Last Name' cellContent='Birkhead' />
          <TableCell cellTitle='Date of Birth' cellContent='10/14/1938' />
          <TableCell cellTitle='SSN' cellContent='666-70-1451' />
        </div>
        <hr />
        <div className='d-flex align-items-center flex-wrap'>
          <TableCell cellTitle='Street Address' cellContent='378 EAST ST' />
          <TableCell cellTitle='City' cellContent='Bloomsburg' />
          <TableCell cellTitle='State' cellContent='Pennsylvania' />
          <TableCell cellTitle='Zip code' cellContent='17815' />
        </div>
        <hr />
        <div className='d-flex align-items-center flex-wrap'>
          <TableCell cellTitle='Phone Number' cellContent='756-876-7967' />
          <TableCell cellTitle='Email' cellContent='test_012423b@123.com' />
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget91}
