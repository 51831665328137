/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import Switch from "react-switch"

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  loanAmount?: number
  totalRepaidAmount?: number
  loanInterestRate?: number
}

const CardsWidget30: FC<Props> = ({
  className,
  chartSize = 100,
  chartLine = 11,
  chartRotate = 145,
  loanAmount = 500,
  totalRepaidAmount = 55,
  loanInterestRate = 20,
}) => {
  const outstandingBalance = 445
  const paymentsMade = 55
  const totalLoanAmount = 500
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  // Toggling between percentages and actual amounts
  const [showPercentages, setShowPercentages] = useState(false)

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, showPercentages])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, loanAmount, totalRepaidAmount, loanInterestRate)
    }, 10)
  }


  // Toggle percentages
  const handleToggle = (checked: boolean) => {
    setShowPercentages(checked);
  }

  // Determine the displayed values based on the toggle state
  const displayedOutstandingBalance = showPercentages ? (outstandingBalance / totalLoanAmount) * 100 : outstandingBalance
  const displayedPaymentsMade = showPercentages ? (paymentsMade / outstandingBalance) * 100 : paymentsMade

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
              Balance Information
              <i className="fas fa-scale-balanced fs-2 text-primary ms-2"></i>
          </span>
          <div className='d-flex align-items-center pt-10'>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>445</span>

          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Outstanding Balance</span>
        </div>
        <div className='card-title d-flex flex-column pt-15'>
          <div className='d-flex align-items-center'>
            <Switch 
              checked={showPercentages} 
              onChange={handleToggle}
              onColor="#86d3ff"
              offColor="#008000"
              handleDiameter={30}
              uncheckedIcon={<div className='d-flex justify-content-center align-items-center h-100 fs-5 pe-1 text-white'>$</div>}
              checkedIcon={<div className='d-flex justify-content-center align-items-center h-100 fs-5 fw-bold pe-1'>%</div>}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={50}
              className="react-switch"
            />
          </div>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center fs-5'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_30_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Outstanding Balance</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {showPercentages
                ? `${displayedOutstandingBalance.toFixed(2)}%`
                : `$${displayedOutstandingBalance.toFixed(2)}`}
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 me-3' style={{backgroundColor: '#fc7651'}}></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Payments Made</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {showPercentages 
                ? `${displayedPaymentsMade.toFixed(2)}%` 
                : `$${displayedPaymentsMade.toFixed(2)}`}  
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet w-8px h-3px rounded-2 me-3'
              style={{backgroundColor: '#E4E6EF'}}
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Total Loan Amount</div>
            <div className=' fw-bolder text-gray-700 text-xxl-end'>
              $500.00
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 100,
  chartLine: number = 11,
  chartRotate: number = 145,
  loanAmount: number = 500,
  totalRepaidAmount: number  = 55,
  loanInterestRate: number = 20,
) {
  const el = document.getElementById('kt_card_widget_30_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((1 / 2 + options.rotate / 72) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'butt' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // calculate percentages
  const outstandingBalance = 500
  const paymentsMade = 55

  // Init 2
  drawCircle('#fc7651', options.lineWidth, outstandingBalance / outstandingBalance || 100 / 100)
  drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, loanAmount / outstandingBalance)
  drawCircle('#fc7651', options.lineWidth, totalRepaidAmount / outstandingBalance)

}

export {CardsWidget30}
