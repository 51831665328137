import React from 'react';
import { useIntl } from 'react-intl';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'; // Adjust path as necessary
import { useAuth } from '../../../../../app/modules/auth';
import { UserType } from '../../../core';

const AdminMenuItems = () => (
  <SidebarMenuItemWithSub to="#" title="Admin" fontIcon="bi-archive" icon="element-plus">
    <SidebarMenuItem to="/admin/user-list" title="User List" hasBullet />
    <SidebarMenuItem to="/admin/provider-list" title="Provider List" hasBullet />
    <SidebarMenuItemWithSub to="#" title="Loans" hasBullet>
      <SidebarMenuItem to="/admin/application" title="Applications" hasBullet />
      <SidebarMenuItem to="#" title="Rejected Applications" hasBullet />
      <SidebarMenuItem to="#" title="Pending Loans" hasBullet />
      <SidebarMenuItem to="#" title="Active Loans" hasBullet />
      <SidebarMenuItem to="#" title="Closed Loans" hasBullet />
    </SidebarMenuItemWithSub>
  </SidebarMenuItemWithSub>
);

const LoansMenuItems = () => (
  <SidebarMenuItemWithSub to="#" title="My Loans" fontIcon="bi-archive" icon="element-plus">
    <SidebarMenuItem to="https://www.welspot.com/loan-calculator/" target="_blank" title="Loan Calculator" hasBullet />
    <SidebarMenuItem to="/new-loan" title="New Loan" hasBullet />
    <SidebarMenuItemWithSub to="#" title="Pending Loans" hasBullet>
      <SidebarMenuItem to="/pendingloans" title="ID: 230" hasBullet />
    </SidebarMenuItemWithSub>
    <SidebarMenuItemWithSub to="#" title="Active Loans" hasBullet>
      <SidebarMenuItem to="/loans" title="ID: 226" hasBullet />
    </SidebarMenuItemWithSub>
    <SidebarMenuItem to="#" title="Closed Loans" hasBullet />
  </SidebarMenuItemWithSub>
);

const WellnessMenuItems = () => (
  <SidebarMenuItemWithSub to="#" title="My Wellness" fontIcon="bi-archive" icon="element-plus">
    <SidebarMenuItemWithSub to="#" title="Financial Health" hasBullet>
      <SidebarMenuItem to="/crafted/widgets/charts" title="Reports" hasBullet />
    </SidebarMenuItemWithSub>
    <SidebarMenuItemWithSub to="#" title="Physical Health" hasBullet>
      <SidebarMenuItem to="/crafted/widgets/charts" title="Reports" hasBullet />
    </SidebarMenuItemWithSub>
    <SidebarMenuItemWithSub to="#" title="Mental Health" hasBullet>
      <SidebarMenuItem to="/apps/user-management/users" title="Reports" hasBullet />
    </SidebarMenuItemWithSub>
  </SidebarMenuItemWithSub>
);

const SidebarMenuMain: React.FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  const adminRoles: UserType[] = ['System Admin', 'Provider Admin'];
  const loanRoles: UserType[] = ['System Admin', 'Provider Admin', 'Provider Staff', 'Borrower'];
  const wellnessRoles: UserType[] = ['System Admin', 'Borrower'];

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/account"
        icon="user"
        title="Profile"
        fontIcon="bi-app-indicator"
      />

      {currentUser && adminRoles.includes(currentUser.Role as UserType) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">Admin</span>
            </div>
          </div>
          <AdminMenuItems />
        </>
      )}

      {currentUser && loanRoles.includes(currentUser.Role as UserType) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">Loans</span>
            </div>
          </div>
          <LoansMenuItems />
        </>
      )}

      {currentUser && wellnessRoles.includes(currentUser.Role as UserType) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">Wellness</span>
            </div>
          </div>
          <WellnessMenuItems />
        </>
      )}
    </>
  );
};

export { SidebarMenuMain };
