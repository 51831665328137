/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../helpers'

type Props = {
  className: string
  link1: string
  link2: string
  link3: string
  description1: string
  description2: string
  description3: string
}

const ListsWidget26 = ({className, link1, link2, link3, description1, description2, description3}: Props) => {

  const rows: Array<{description: string, link: string}> = [
    {
      description: description1,
      link: link1
    },
    {
      description: description2,
      link: link2
    },
    {
      description: description3,
      link: link3
    },
  ]

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>External Links</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack'>
              <a href={row.link} className='text-primary fw-semibold fs-6 me-2' target="_blank" rel="noreferrer">
                {row.description}
              </a>
              <button
                type='button'
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
              >
                <a href={row.link} className='text-primary fw-semibold fs-6 me-2' target="_blank" rel="noreferrer">
                  <KTIcon iconName='exit-right-corner' className='fs-2' />
                </a>
              </button>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export {ListsWidget26}
