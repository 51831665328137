/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
}

const EngageWidget11 = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='mb-10 mt-0'>
        <div className='fs-2hx fw-bold text-gray-800 text-center mb-5 mt-0'>
          Find a Provider
        </div>
        
        <div className="google-map-code d-flex justify-content-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25895663.485556163!2d-95.665!3d37.599999999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sus!4v1689108158641!5m2!1sen!2sus"
            width="650" 
            height="350" 
            allowFullScreen={false} 
            aria-hidden="false" 
            title="Google Map"
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            style={{
              border:0,
              borderRadius: '10px',
            }} 
            >
          </iframe>
        </div>

      </div>
      
    </div>
  </div>
)
export {EngageWidget11}
