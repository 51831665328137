import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { TestPage } from "../pages/TestPage"
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { UserType } from '../../_metronic/layout/core'

const PrivateRoutes = () => {
  const LoanPage = lazy(() => import('../modules/loans/LoanPage'))
  const UserList = lazy(() => import('../modules/admin/user-list/UserList'))
  const ProviderList = lazy(() => import('../modules/admin/provider-list/ProviderList'))
  const Application = lazy(() => import('../modules/admin/application/Application'))
  const PendingLoanPage = lazy(() => import('../modules/pendingloans/PendingLoanPage'))
  const NewLoanPage = lazy(() => import('../modules/new-loan/NewLoanPage'))
  const OffersPage = lazy(() => import('../modules/new-loan/OffersPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const { currentUser } = useAuth()

  // Define which user types are allowed to access specific routes
  const adminRoles: UserType[] = ['System Admin', 'Provider Admin']
  const loanRoles: UserType[] = ['System Admin', 'Provider Admin', 'Provider Staff', 'Borrower']
  const chatRoles: UserType[] = ['System Admin', 'Provider Staff']
  const redirectRoles: UserType[] = ['Borrower']
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to new-loan after successful login/registration */}
        {currentUser && redirectRoles.includes(currentUser.Role as UserType) ? (
          <Route path='auth/*' element={<Navigate to='/new-loan' />} />
        ): (<Route path='auth/*' element={<Navigate to='/dashboard' />} />)}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='test' element={<TestPage />} />
        {/* Lazy Modules */}
        {/* Admin and Provider Admin users */}
        {currentUser && adminRoles.includes(currentUser.Role as UserType) && (
          <>
            <Route path='admin/user-list/*' element={<SuspensedView><UserList userType={"System Admin"} /></SuspensedView>} />
            <Route path='admin/provider-list/*' element={<SuspensedView><ProviderList userType={"Provider Admin"} /></SuspensedView>} />
            <Route path='admin/application/*' element={<SuspensedView><Application userType={"System Admin"} caseType="applications" /></SuspensedView>} />
          </>
        )}

        {/* Loans accessible by multiple roles */}
        {currentUser && loanRoles.includes(currentUser.Role as UserType) && (
          <>
            <Route path='loans/*' element={<SuspensedView><LoanPage /></SuspensedView>} />
            <Route path='new-loan' element={<SuspensedView><NewLoanPage /></SuspensedView>} />
            <Route path='new-loan/loan-offers' element={<SuspensedView><OffersPage /></SuspensedView>} />
            <Route path='pendingloans/*' element={<SuspensedView><PendingLoanPage /></SuspensedView>} />
          </>
        )}

        {/* Chat accessible by Admin and Provider Staff */}
        {currentUser && chatRoles.includes(currentUser.Role as UserType) && (
          <Route path='apps/chat/*' element={<SuspensedView><ChatPage /></SuspensedView>} />
        )}

        <Route path='crafted/pages/wizards/*' element={<SuspensedView><WizardsPage /></SuspensedView>} />
        <Route path='crafted/widgets/*' element={<SuspensedView><WidgetsPage /></SuspensedView>} />
        <Route path='account/*' element={<SuspensedView><AccountPage /></SuspensedView>} />
        <Route path='apps/user-management/*' element={<SuspensedView><UsersPage /></SuspensedView>} />
        <Route path='reports/*' element={<SuspensedView><WidgetsPage /></SuspensedView>} />
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
