import React from 'react'
import { KTIcon } from '../../_metronic/helpers'
import icons from '../../_metronic/helpers/icons-config/icons'
import { CSSProperties } from 'react'

const TestPage: React.FC = () => {
  const gridStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    gap: '15px',
  }

  const iconContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '10px',
    fontSize: '12px', 
  }

  const iconNameStyle: CSSProperties = {
    marginTop: '10px',
  }

  return (
    <div style={gridStyle}>
      {Object.keys(icons).map((iconName, index) => {
        return (
          <div key={index} style={iconContainerStyle}>
            <KTIcon iconName={iconName} iconType='duotone' className='fs-1' />
            <div style={iconNameStyle}>{iconName}</div>
          </div>
        )
      })}
    </div>
  )
}

export { TestPage }
