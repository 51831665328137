/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
}

const ListsWidget31: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
      <h3 className='card-title fw-bold text-dark'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Procedure Information
            <i className="fa-solid fa-clipboard-list fs-2 text-primary ms-2"></i>
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        <div className='d-flex align-items-center mb-8 mt-4'>
          <div className='flex-grow-1'>
            <span className='text-muted fw-semibold d-block'>
              Procedure Name
            </span>
            <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Facelift
            </span>
          </div>
          <span className='badge badge-light-success fs-8 fw-bold'>
            Verified
          </span>
        </div>
        <div className='d-flex align-items-center mb-8'>
          <div className='flex-grow-1'>
            <span className='text-muted fw-semibold d-block'>
              Procedure Date
            </span>
            <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
              05/02/2023
            </span>
          </div>
          <span className='badge badge-light-success fs-8 fw-bold'>
            Scheduled
          </span>
        </div>
      </div>
      
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget31}
